import firebase from "firebase/app"
import "firebase/auth"
import "firebase/analytics"
import "firebase/firestore"
import "firebase/storage"
require("dotenv").config()

const config = {
  apiKey: "AIzaSyAcZi3LGVrC4odB1ZbD0UgL8FYklMgcfHY",
  authDomain: "cloudpin-pin-669.firebaseapp.com",
  databaseURL: "https://cloudpin-pin-669.firebaseio.com",
  projectId: "cloudpin-pin-669",
  storageBucket: "cloudpin-pin-669.appspot.com",
  messagingSenderId: "63023708585",
  appId: "1:63023708585:web:c4c954b128e948a5e46cb8",
  measurementId: "G-QNYSYQ50G9"
}


export default firebase.apps.length
  ? firebase.app()
  : firebase.initializeApp(config, "CloudPin")
